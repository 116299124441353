function openNav() {
  document.getElementById("sidebar").style.width = "220px";
}

/* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
function closeNav() {
  document.getElementById("sidebar").style.width = "0";
}

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.four-avenues .card').forEach(item => {
    item.addEventListener('click', event => {
      event.preventDefault();
      item.classList.toggle('flip');
    })
  })

  var editors = document.querySelectorAll("trix-editor")

  editors.forEach(function(editor){
    if (editor && editor.getAttribute('disabled') === 'disabled') {
    	editor.contentEditable = false
    }	
  })

  if (document.getElementsByClassName('menu-item collapsible-active').length == 1) {
    document.getElementsByClassName('overlay')[0].style.display = 'block';
    document.getElementById('sidebar-close').classList.add('dark');
  }

  document.querySelectorAll('.menu-collapsible').forEach(item => {
    item.addEventListener('click', event => {
      var active = document.getElementsByClassName('menu-item active')[0]

      if (active) {
        active.classList.remove('active', 'collapsible-active');
      }

      item.closest('.menu-item').classList.add('active', 'collapsible-active');

      document.getElementsByClassName('overlay')[0].style.display = 'block';
      document.getElementById('sidebar-close').classList.add('dark');
    })
  })

  document.getElementById('mobile-menu').addEventListener('click', event => {
    openNav();
  })

  document.getElementById('sidebar-close').addEventListener('click', event => {
    closeNav();
  })

});
