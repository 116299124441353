import {Rails} from 'packs/application.js';
import debounce from './application';


function plan_count() {
  return $('#plans tbody tr').length;
}

function adjustListNumbering() {
  $("#plans tbody tr").each(function (index) {
    let i = index + 1;
    let field = $(this).find('.order');
    let text = i + '.';
    if (index === 0) {
      text += '<abbr title="required">*</abbr>';
    }
    field.html(text);
  });
}


function adjustAddStepAvailability() {
  let button = $('.add-step');
  if (plan_count() < 20) {
    button.show();
  } else {
    button.hide();
    // Show message?
  }
}


function adjustButtonEnabledState(button, enabled) {
  if (enabled) {
    button.removeClass('disabled');
    button.removeAttr('disabled');
  } else {
    button.addClass('disabled');
    button.attr('disabled', 'disabled');
  }
}


function adjustDeleteStepAvailability() {
  adjustButtonEnabledState(
    $('.delete-step'),
    plan_count() > 1
  );
}

function valueIsPresent(index, element) {
  return $(element).val() !== '';
}


function adjustNextButtonAvailability() {
  let button = $('#btn-next-plan');
  if ($('textarea').is(valueIsPresent)) {
    $('#form-bottom-warning').html('');
    adjustButtonEnabledState(button, true)
  } else {
    $('#form-bottom-warning').html('Please add at least one task to continue.');
    adjustButtonEnabledState(button, false)
  }

}

function submitPlanForm() {
  console.log('Updating plan list...')
  let form = $('#plans')[0]
  Rails.fire(form, 'submit');
}


function handleDateChange(event) {
  let date = $(event.target).val();
  let hiddenField = $(event.target).prev('input');
  if (date === '') {
    hiddenField.val('');
  } else {
    let elements = date.split('/')
    hiddenField.val([elements[2], elements[0], elements[1]].join('-'));
  }

  submitPlanForm();
}

function handleTaskChange() {
  adjustElementAvailability();

  submitPlanForm();
}


function adjustElementAvailability() {
  adjustListNumbering();
  adjustAddStepAvailability();
  adjustDeleteStepAvailability();
  adjustNextButtonAvailability();
}

function datePickerOptions() {
  return {
    showButtonPanel: true,
    closeText: 'Clear',
    onClose: function (dateText, inst) {
      if ($(window.event.srcElement).hasClass('ui-datepicker-close')) {
        let element = document.getElementById(this.id);
        element.value = '';
        $(element).change();
      }
    }
  }
}

window.handleTaskChange = handleTaskChange; // Expose for use in rjs responses
window.datePickerOptions = datePickerOptions; // Expose for use in rjs responses


$(document).on('click', 'a.delete-step', function (event, ui) {
  event.preventDefault();
  let row = $(event.target).closest('tr');
  row.remove();

  handleTaskChange();
});

document.addEventListener("turbolinks:load", () => {
  // Ensure starting states are correct.
  adjustElementAvailability();

  // Make lists sortable.
  let sortableTarget = "#plans tbody[data-sortable='true']";
  $(sortableTarget).sortable().disableSelection();
})


let minTimeoutBetweenCallsInMilliSeconds = 750;
$(document).on('input propertychange',
  '#plans tbody textarea',
  debounce(handleTaskChange, minTimeoutBetweenCallsInMilliSeconds));


$(document).on('change',
  '#plans tbody .date-field',
  handleDateChange);


$(document).on('sortupdate',
  '#plans tbody',
  handleTaskChange);


$(function () {
  $(".date-field").datepicker(datePickerOptions());
})
