import {Rails} from 'packs/application.js';

function adjustNextButtonAvailability() {
  let selection_count = $('#consideration-lists form ul li').length;
  let button = $('#btn-next');
  if (selection_count > 0 && selection_count < 6) {
    button.removeClass('disabled');
    button.removeAttr('disabled');
    $('#form-bottom-warning').hide();
  } else {
    button.addClass('disabled');
    button.attr('disabled', 'disabled');
    $('#form-bottom-warning').show();
  }
}

function adjustListNumbering() {
  $("#consideration-lists form ul li").each(function (index) {
    let i = index + 1;
    let field = $(this).find('.order');
    field.text(i + '.');
    if (i > 5) {
      field.addClass('error');
    } else {
      field.removeClass('error');
    }
  });
}

// Submit form on sort.
$(document).on('sortupdate',
  '#consideration-lists form ul',
  function (event, ui) {
    console.log('Updating consideration list!')
    adjustListNumbering();
    let form = $('#consideration-lists form')[0]
    Rails.fire(form, 'submit');
    adjustNextButtonAvailability();
  });

// Do not follow disabled links.
$(document).on('click', 'a.disabled, a:disabled',
  function (event, ui) {
    event.preventDefault();
  });

document.addEventListener("turbolinks:load", () => {
  // Ensure starting states are correct.
  adjustNextButtonAvailability();
  adjustListNumbering();

  // Make lists sortable.
  let sortableTarget = "#consideration-lists ul";
  let considerationList = document.getElementById("consideration-lists");

  $(sortableTarget).sortable({
    connectWith: sortableTarget,
    disabled: (considerationList && considerationList.dataset.disabled) == 'true'
  }).disableSelection();

})
