document.addEventListener("turbolinks:load", function() {

  var maximum = $('.accepts').length;
  var $rejected = $('.accepts input[value="rejected"]:checked');

  if (maximum == $rejected.length) {
    $('#rejectComparison').modal({ show: true, keyboard: false, backdrop: 'static' });
  }

  $('.accepts input[type=radio]').on('change', function(e) {
    //e.preventDefault();

    var checked = $('.accepts input[type=radio]:checked');

    if (maximum === $(checked).length) {
      var $accepted = $('.accepts input[value="accepted"]:checked');
      $rejected = $('.accepts input[value="rejected"]:checked');

      if ($accepted.length > 1) {
        $('#acceptComparison').modal('show');
      } else if (maximum === $rejected.length) {
        $('#rejectComparison').modal('show');
      } else {
        $('#acceptOption').modal('show');
      }
    }
  })

  $(document).on('change', '#accepts_yes', function() {
    $('#existing-options').removeClass('d-none');
    $('#new-option').addClass('d-none')
  })

  $(document).on('change', '#accepts_no', function() {
    $('#new-option').removeClass('d-none')
    $('#existing-options').addClass('d-none');
  })


  $(document).on('click', '#resultsCombine', function() {
    $('#select-options').addClass('d-none');
    $('#combine-option').removeClass('d-none');

    var $selected = $('#select-options input[type=checkbox]:checked');

    var title = '';
    var description = '';

    $.each($selected, function(i, val) {
      title += (' ' + $(val).data('title'));
      description += (' ' + $(val).data('description'));

    })

    $('#combined_option_title').val(title);
    $('#combined_option_description').val(description);
  })

  $('#acceptComparison').on('show.bs.modal', function (event) {
    var $rejected = $('.accepts input[value="rejected"]:checked');

    $.each($rejected, function(i, val) {
      var modal = $(this)
      var optionId = $(val).data('optionId'); 

      reject = $('input[type=checkbox][value=' + optionId + ']');
      reject.parents('span').remove();
    })
  })

  $('#acceptOption').on('show.bs.modal', function(event) {
    var modal = $(this);
    var $accepted = $('.accepts input[value="accepted"]:checked');

    modal.find('#option_id').val($accepted.data('optionId'));
  })

})