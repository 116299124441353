// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

export const Rails = require('@rails/ujs')
Rails.start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('d3')

require('jquery')

import 'bootstrap'

let bootstrapTooltip = jQuery.fn.tooltip;

require('jquery-ui')
jQuery.fn.tooltip = bootstrapTooltip; // Override the jQuery tooltip function with the Bootstrap tooltip.

require('@nathanvda/cocoon')

require('src/application')
require('src/concerns')
require('src/options')
require('src/pages')
require('src/radarChart')
require('src/responses')
require('src/results')
require('src/stakeholders')
require('src/tooltips')
require('src/considerations')
require('src/plans')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")


