document.addEventListener("turbolinks:load", function() {
  $('#stakeholders')
    .on('cocoon:after-insert', function(event, insertedItem) {
      index = $('#stakeholders-list .nested-fields').length

      if (index > 5) {
        $('.add_fields').hide();
      }

      insertedItem.find('.issue_stakeholders_description label').text('Stakeholder # ' + index);
      insertedItem.find('.issue_stakeholders_order input').val(index - 1);
    })
})
