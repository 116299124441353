document.addEventListener("turbolinks:load", function() {
  $('#options')
    .on('cocoon:after-insert', function(event, insertedItem) {
      index = $('#options-list .nested-fields').length;

      if (index > 5) {
        $('.add_fields').hide();
      }

      insertedItem.find('.option-label strong').text('Option ' + index);
      insertedItem.find('.issue_options_order input').val(index - 1);
    })
})
