document.addEventListener("turbolinks:load", function() {
  adjustNextButtonAvailability();

  $('.basic-questions input[type="radio"], .questions input[type="radio"]').on('change', function(e) {
    e.preventDefault();

    var form = $(this).closest('form');

    $.ajax({
      type: 'POST',
    	url: form.attr('action'),
    	data: form.serialize(),
    	dataType: 'script'
    })
    .done(function() {
    	adjustNextButtonAvailability();
    })
    .fail(function() {
    	alert('An unexpected error has occurred. Please reload and try again.')
    })
    
  })
})

function adjustNextButtonAvailability() {
  $degrees = $(".questions__degree, .basic-questions__degree");

  if ($degrees.length === $degrees.find('input[type=radio]:checked').length) {
    $('#response-continue-warning').hide();
    $('.next-btn').removeClass('disabled'); 
  } else {
    $('#response-continue-warning').show();
    $('.next-btn').addClass('disabled');  
  }
}
