document.addEventListener("turbolinks:load", function () {
  $('#concerns')
    .on('cocoon:after-insert', function (event, insertedItem) {
      index = $('#concerns-list .nested-fields').length

      if (index > 5) {
        $('.add_fields').hide();
      }
      var text;

      if (index === 2) {
        text = 'Secondary Concern';
      } else if (index === 3) {
        text = 'Tertiary Concern';
      } else {
        text = 'Additional Concern';
      }

      insertedItem.find('label').text(text);
      insertedItem.find('.issue_concerns_order input').val(index - 1);
    })
})
